
import Lottie from "lottie-react";
import "./loading.component.scss";
import LoadingAnimation from "../../assets/json/loading-questions.json";
import OpenDoorAnimation from "../../assets/json/opendoor.json";
import { useContext, useEffect, useState } from "react";
import { ContextProvider } from "../../context/context-provider.context";
import ApiServices from "../../services/api.service";
import apiConfig from "../../api-config";
import { useLocation, useNavigate } from "react-router-dom";
import { AnswerModel } from "../../model/answer.model";
import PRE_QUESTION_JSON from "../../assets/json/pre-questions.json";
import InputModels from "../../model/input.model";
import ErrorModal from "../../modal/error.modal";
import { UplaodResumeModel } from "../../model/upload-resume.model";
import UtilityService from "../../utility/utilities";
import GAConfig from "../../ga-config";
const LoadingComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { inputModel, answerModel, setAnswerModel }: any = useContext(ContextProvider)
    const [isDone, setIsDone] = useState(false);
    const [isErrorModal, setErrorModal] = useState(false);
    const [isBackHome, setIsBackHome] = useState(false);
    const [resultModel, setResultModel] = useState<AnswerModel>();
    const [messageError, setMessageError] = useState("");
    const [objectResult, setObjectResult] = useState<InputModels>();
    useEffect(() => {
        if (inputModel) {
            if (location.state.input) {
                setObjectResult(location.state.input);
            }
        } else {
            navigate('/')
        }

    }, [inputModel])

    const handleErrorModal = () => {
        if (isErrorModal) {
            setErrorModal(false);
            if (isBackHome) {
                navigate('/')
            } else {
                if (resultModel) {
                    setIsDone(true);
                }

            }

        } else {
            setErrorModal(true);
        }
    }





    const callApiResult = () => {

        if (objectResult) {
            ApiServices().uploadResume(objectResult.resume, objectResult.filename).then((res: UplaodResumeModel) => {

                if (res.resume_key) {
                    const inputObject: InputModels = {
                        job_title: objectResult.job_title,
                        job_description: objectResult.job_description,
                        job_experience: objectResult.job_experience,
                        job_salary: objectResult.job_salary,
                        question_amount: objectResult.question_amount,
                        question_language: objectResult.question_language,
                        resume_key: res.resume_key
                    }
                    ApiServices().postCustom(inputObject, apiConfig.path.question).then((resData: any) => {
                        const res = resData as AnswerModel;
                        if (res && res.questions) {
                            let questions: string[] = []
                            let answer: string[] = [];

                            res.questions.map((item: string, index: number) => {
                                if (item.length > 5) {
                                    questions.push(item);
                                    answer.push("");
                                }

                            })
                            const answerMod: AnswerModel = {
                                questions: questions,
                                answers: answer,
                                company: inputModel.company
                            }
                            UtilityService().clickSendEvent(GAConfig.event.load, "Load Questions " + inputObject.job_title)
                            setAnswerModel(answerMod);
                            setResultModel(answerMod);
                            if (res.is_resume == false) {
                                setErrorModal(true);
                                setIsBackHome(false);
                                setMessageError("ไฟล์ที่อัปโหลดไม่มีข้อมูลของ Resume. <br>กดปิดเพื่อทดลองสัมภาษณ์ด้วยตำแหน่ง " + objectResult.job_title)
                            } else {
                                setIsDone(true);
                            }

                        } else {
                            if (resData.message == "Request failed with status code 413") {
                                setErrorModal(true);
                                setIsBackHome(true);
                                setMessageError("ไฟล์ที่อัปโหลดมีขนาดใหญ่เกินไป. (ขนาดต้องไม่เกิน 20 MB.)")
                            } else {
                                setErrorModal(true);
                                setIsBackHome(true);
                                setMessageError("เกิดข้อผิดพลาดในระบบ. กรุณาลองใหม่อีกครั้ง")
                            }


                        }
                    }).catch((err: any) => {
                        setErrorModal(true);
                        setIsBackHome(true);
                        setMessageError("เกิดข้อผิดพลาดในระบบ. กรุณาลองใหม่อีกครั้ง")
                    })
                }
            })

        }
    }

    const onanimationEnd = () => {
        navigate('/questions', { state: { results: answerModel, input: inputModel } });

    }

    useEffect(() => {
        if (objectResult) {
            callApiResult();
        }
    }, [objectResult])

    useEffect(() => {

    }, [isDone])
    return (
        <>
            <div className="loading-comp">
                <div className="loading-control">
                    {
                        isDone ? <>

                            <div className="loading-img-door-control">
                                <span className="loading-img-door-text">ถึงเวลาสัมภาษณ์แล้ว</span>
                                <Lottie animationData={OpenDoorAnimation} loop={false} onComplete={onanimationEnd}
                                    className="loading-img-door" />
                            </div>
                        </> :

                            <><Lottie animationData={LoadingAnimation} loop={true} className="loading-img" />
                                <span className="loading-text">Loading . . . </span>
                            </>
                    }
                </div>
            </div>
            {
                isErrorModal &&
                <ErrorModal open={isErrorModal} setOpen={handleErrorModal} message={messageError} title={"Warning"}></ErrorModal>
            }

        </>
    )
}

export default LoadingComponent;