import "./header.component.scss";
import LOGO_DARK from "../../assets/images/jobhack-logo-dark-interview.png";
import config from "../../config";
import MENU_NAV from "../../assets/images/menu-nav.png";
import { useEffect, useState, useContext } from "react";
import { InputOneProfileModel } from "../../model/external/input-oneprofile.model";
import { useLocation } from "react-router-dom";
const HeaderComponent = () => {
    const location = useLocation();
    const [isToggle, setIsToggle] = useState(false);
    const searchParams = new URLSearchParams(location.search);
    const [inputOneProfile, setInputOneProfile] = useState<InputOneProfileModel>();
    useEffect(() => {
      
        const queryParams: InputOneProfileModel = {};
        searchParams.forEach((value, key) => {
            if (key == "input") {
                const jsonObj = JSON.parse(value);
                queryParams[key] = jsonObj;
            } else {
                queryParams[key] = value;
            }

        });
        console.log(queryParams);
        setInputOneProfile(queryParams);
    },[])

    const clickMenu = (url) => {
        window.open(url, "_blank");
    }

    const clickToggle = () => {

        if (isToggle) {
            setIsToggle(false);
            document.body.style.overflow = "auto"
        } else {
            setIsToggle(true);
            document.body.style.overflow = "hidden"
        }
    }
    return (
        <>
        {
            inputOneProfile && !inputOneProfile.isHeader ?
            <></> : 
            <div className="header-comp">
                <div className="header-show-mobile">
                    <div className="header-control">
                        <img src={LOGO_DARK} className="header-logo-img" onClick={() => clickMenu("/")}></img>
                        <div className="header-menu-control d-md-flex d-none">
                            <span className="header-menu-link" onClick={() => clickMenu(config.app.salary_checker)}>เช็กเงินเดือน</span>
                            <span className="header-menu-link" onClick={() => clickMenu(config.app.resume_checker)}>เช็ก Resume</span>
                            <span className="header-menu-link" onClick={() => clickMenu(config.app.personality)}>แบบทดสอบบุคลิกภาพ</span>
                            <span className="header-menu-link" onClick={() => clickMenu(config.app.jobhack)}>สมัครงาน</span>
                        </div>
                        <div className="header-menu-control d-md-none d-flex">
                            <img src={MENU_NAV} className="header-menu-nav" onClick={clickToggle}></img>
                        </div>
                    </div>
                    {
                        isToggle &&
                        <div className="header-open-nav">
                            <div className="header-menu-control">
                                <div className="header-menu-nav-control">
                                    <span className="header-menu-link" onClick={() => clickMenu(config.app.salary_checker)}>เช็กเงินเดือน</span>
                                    <span className="header-menu-link" onClick={() => clickMenu(config.app.resume_checker)}>เช็ก Resume</span>
                                    <span className="header-menu-link" onClick={() => clickMenu(config.app.personality)}>แบบทดสอบบุคลิกภาพ</span>
                                    <span className="header-menu-link" onClick={() => clickMenu(config.app.jobhack)}>สมัครงาน</span>
                                </div>
                            </div>

                        </div>
                    }
                </div>
            </div>
        }
            
        </>
    )
}

export default HeaderComponent;