import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./result.component.scss";
import "../step/step.component.scss";
import ResultModel from "../../model/result.model";
import { Button, Form } from "react-bootstrap";
import ApiServices from "../../services/api.service";
import { useContext } from "react";
import { ContextProvider } from "../../context/context-provider.context";
import SkillSuggestion from "../../model/skill-suggestion.model";
import SalaryOfferModel from "../../model/salary-offer.model";
import HeaderComponent from "../header/header.component";
import ARROW_IMG_LEFT from "../../assets/images/left-arrow-dark.png";
import SpeechComponent from "../speech/speech.component";
import CANDIDATE from "../../assets/images/candidate.png";
import HR_ASK from "../../assets/images/hr-ask-result.png";
import SPEAKER from "../../assets/images/speaker.png";
import ARROW_RIGHT from "../../assets/images/right-arrow-dark.png";
import InputModels from "../../model/input.model";
import { AnswerModel } from "../../model/answer.model";
import GAConfig from "../../ga-config";
import UtilityService from "../../utility/utilities";
import config from "../../config";
const ResultComponent  = () => {
    const myRef = useRef<HTMLInputElement>(null)
    const [inputModel, setInputModel] = useState<InputModels>();
    const [answerModel, setAnswerModel] = useState<AnswerModel>();
    const { state } = useLocation();
    const location = useLocation();
    const [resultModel, setResultModel] = useState<ResultModel>();
    const [SkillSuggest, setSkillSuggest] = useState<SkillSuggestion>();
    const [salaryOffer, setSalaryOffer] = useState<SalaryOfferModel>();
    const { utterance }: any = useContext(ContextProvider)
    const { synth }: any = useContext(ContextProvider);
    const [resultSpeech, setResultSpeech] = useState('')
    const [inputText, setInputText] = useState('')
    const [ isShowAnswer, setIsShowAnswer] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (state && state.input) {
            setInputModel(state.input);
        }
        if (state && state.answer) {
            setAnswerModel(state.answer);
        }
        if (location.state.result) {
            UtilityService().setPageTitle('/result');
            setResultModel(location.state.result);
        } else {
            navigate('/')
        }

    }, [])


    useEffect(() => {
        if (resultModel) {
            const uttstep = utterance as SpeechSynthesisUtterance;
            uttstep.text = resultModel.result;
            uttstep.rate = 1;
            uttstep.pitch = 1;
            uttstep.volume = 2;
            window.speechSynthesis.cancel();
            window.speechSynthesis.speak(uttstep);
        }
    }, [resultModel])
    useEffect(() => {
        // Inside this callback function we perform our side effects.
        window.speechSynthesis.cancel();
       
      });
    const speak = () => {
        if (resultModel && utterance && synth) {

            const uttstep = utterance as SpeechSynthesisUtterance;
            uttstep.text = resultModel.result!;
            uttstep.rate = 1;
            uttstep.pitch = 1;
            uttstep.volume = 2;
            window.speechSynthesis.cancel();
            window.speechSynthesis.speak(uttstep);
        }
    }
   

    useEffect(() => {
        if (inputModel) {
            getSkillSuggest();
            getSalaryOffer();
        }
    }, [inputModel])

    const getSkillSuggest = () => {
        if (inputModel) {
            const ObjectResult = {
                job_title: inputModel.job_title
            }
            ApiServices().getSkillSuggest(ObjectResult).then((res) => {
                setSkillSuggest(res);
            })
        }



    }

    const clickShowAnswer = () => {
        UtilityService().clickSendEvent(GAConfig.event.click, "Click show answer")
        setIsShowAnswer(true);
    }

    const getSalaryOffer = () => {
        if (inputModel) {
            const ObjectResult = {
                job_title: inputModel.job_title,
                year_of_experience: inputModel.job_experience,
                current_salary: inputModel.job_salary
            }
            ApiServices().getSalaryOffer(ObjectResult).then((res) => {
                setSalaryOffer(res);
            })
        }

    }

    const testAgain = () => {
        navigate('/');
    }

    const backtoHome = () => {
        navigate('/');
    }
    const handleTextArea = (e) => {
        setInputText(e.target.value);

    }

    const onFeedback = () => {

    }
    const scrollFeedback = () => {

        if (myRef.current) {
            myRef.current.scrollIntoView()
        }

    }
    return (
        <>
            <HeaderComponent></HeaderComponent>
            <div className="container step-comp result-comp">
                <div className="step-control">

                    <div className="step-right-control">
                    {
                            inputModel && inputModel.is_external ? <></> : <div className="breadcrumbs-control" onClick={backtoHome}>
                                <img src={ARROW_IMG_LEFT} className="breadcrumbs-img"></img>
                                <span className="breadcrumbs-text">ย้อนกลับ</span>
                            </div>
                        }
                      
                        {
                            (resultModel && inputModel) && <>
                                <div className="step-questions-control result-control-width">


                                    <div className="result-hr-ask-control">

                                        <img src={HR_ASK} className="result-hr-result-img"></img>

                                        <div className="result-hr-bubble-control">
                                            <blockquote>

                                                <p className="blockquote-header">{inputModel!.question_language == "ENGLISH" ? "Result from interview" : "ผลลัพธ์จากการสัมภาษณ์"}</p>
                                                <p className="blockquote-text">{resultModel.result}</p>

                                                <img src={SPEAKER} className="speak-img mx-auto" onClick={speak}></img>
                                                <div className="result-answer-control">
                                                    <span className="result-answer-header" onClick={clickShowAnswer}>คลิกเพื่อดูคำตอบของคุณ</span>
                                                    {
                                                        isShowAnswer &&

                                                        (answerModel && answerModel.questions && answerModel.answers && answerModel.answers.length > 0 &&
                                                            answerModel.questions!.length > 0) &&

                                                        answerModel.questions?.map((item: string, index: number) => {
                                                            return <div className="result-answer-text-control">
                                                                <span className="result-answer-text-bold"> Q: <span className="result-answer-text">{item}</span> </span>
                                                                <span className="result-answer-text-bold"> A: <span className="result-answer-text">{answerModel.answers![index]}</span> </span>
                                                                <div className="result-answer-text-divider"></div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                                {/* <span className="result-feedback-text" onClick={scrollFeedback}>คุณคิดเห็นยังไงกับผลลัพธ์ที่ได้</span> */}

                                            </blockquote>
                                        </div>
                                    </div>


                                    {/* <div className="step-questions-textarea-control result-feedback-control" ref={myRef}>


                                        <div className="step-questions-textarea">
                                            <span className="result-question-feedback-text">จากผลลัพธ์ข้างต้นคุณให้ Feedback กับผลลัพธ์อย่างไร</span>
                                            <Form.Control as="textarea" rows={5}
                                                value={inputText}
                                                onChange={(e) => handleTextArea(e)} ></Form.Control>
                                            <div className="step-questions-button-control">
                                                <Button className="step-questions-button" onClick={onFeedback}>Feedback</Button>
                                            </div>
                                        </div>

                                        <img src={CANDIDATE} className="step-questions-candidate-img"></img>
                                        <div className="result-mic-control">

                                            <SpeechComponent setText={setResultSpeech} setInputText={setInputText} inputText={inputText}></SpeechComponent>


                                        </div>
                                       

                                    </div> */}

                                </div>

                            </>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}

export default ResultComponent;