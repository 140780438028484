import Lottie from "lottie-react";
import "./loading-result.component.scss";
import LoadingAnimation from "../../assets/json/loading-api.json";
import { useEffect, useState } from "react";
import { AnswerModel } from "../../model/answer.model";
import { useLocation, useNavigate } from "react-router-dom";
import ApiServices from "../../services/api.service";
import ErrorModal from "../../modal/error.modal";
import GAConfig from "../../ga-config";
import UtilityService from "../../utility/utilities";
import ExternalService from "../../services/external/external.service";
import config from "../../config";

const LoadingResultComponent = ({answers, inputModel} : any) => {
    const [answerModel, setAnswerModel] = useState<AnswerModel>();
    const navigate = useNavigate();
    const [isErrorModal , setErrorModal] = useState(false);
    const [isBackHome , setIsBackHome] = useState(false);
    const [messageError, setMessageError] = useState("");
    useEffect(() => {

        if (answers) {
            setAnswerModel(answers);
        }

    }, [answers])

    useEffect(() => {

        if(answerModel){
            postAnswer();
        }
    }, [answerModel])

    const handleErrorModal = () => {
        if(isErrorModal){
            setErrorModal(false);
            if(isBackHome){
                navigate('/questions')
            }else{
               
            }
        }else{
            setErrorModal(true);
        }
    }

    const postAnswer = () => {

        if(answerModel){
            if(!inputModel.is_external){
                try{
                    ApiServices().postAnswerInterviewQuestion(answerModel).then((res) => {
                        if(res){
                            UtilityService().clickSendEvent("Load result complete " , "Load result complete ")
                            navigate('/result', { state: { result: res, answer: answerModel, input: inputModel } })
                        }else{
                            setErrorModal(true);
                            setIsBackHome(true);
                            setMessageError("เกิดข้อผิดพลาดในระบบ. กรุณาลองใหม่อีกครั้ง")
                        }
                       
                    }).catch((err) => {
                        setErrorModal(true);
                        setIsBackHome(true);
                        setMessageError("เกิดข้อผิดพลาดในระบบ. กรุณาลองใหม่อีกครั้ง")
                    })
                }catch{
                    setErrorModal(true);
                    setIsBackHome(true);
                    setMessageError("เกิดข้อผิดพลาดในระบบ. กรุณาลองใหม่อีกครั้ง")
                }
            }else{
                answerModel.job_title = inputModel.job_title;
                answerModel.job_experience = inputModel.job_experience;
                answerModel.job_salary = inputModel.job_salary;
                answerModel.resume_key = inputModel.resume_key;
                answerModel.job_description = inputModel.job_description;
                answerModel.question_amount = inputModel.question_amount;
                answerModel.question_language = inputModel.question_language;
                try{
                    ExternalService().postResult(answerModel, inputModel.access_token).then((res) => {
                        if(res){
                            UtilityService().clickSendEvent("Load result complete " , "Load result complete ");
                            sendDataBack();
                            navigate('/result', { state: { result: res, answer: answerModel, input: inputModel } })
                        }else{
                            setErrorModal(true);
                            setIsBackHome(true);
                            setMessageError("เกิดข้อผิดพลาดในระบบ. กรุณาลองใหม่อีกครั้ง")
                        }
                       
                    }).catch((err) => {
                        setErrorModal(true);
                        setIsBackHome(true);
                        setMessageError("เกิดข้อผิดพลาดในระบบ. กรุณาลองใหม่อีกครั้ง")
                    })
                }catch{
                    setErrorModal(true);
                    setIsBackHome(true);
                    setMessageError("เกิดข้อผิดพลาดในระบบ. กรุณาลองใหม่อีกครั้ง")
                }
            }
            
           
        }
       
    }
    const sendDataBack =()=> {
        const objecResult = {
            is_complete: true
        }
        window.parent.postMessage(objecResult, config.one_profile_product_url);
      }

    return (
        <>
            <div className="loading-result-comp">
                <div className="loading-result-control">
                    <Lottie animationData={LoadingAnimation} loop={true} className="loading-result-img" />
                    <span className="loading-result-text">กำลังส่งคำตอบให้ HR <br></br></span>
                </div>
            </div>
            {
                isErrorModal && 
                <ErrorModal open={isErrorModal} setOpen={handleErrorModal} message={messageError} title={"Warning"}></ErrorModal>
            }
        </>
    )
}

export default LoadingResultComponent;