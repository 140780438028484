import axios from "axios";
import apiConfig from "../api-config"
import config from "../config"
import { fileURLToPath } from "url";
import { useContext } from "react";
import { ContextProvider } from "../context/context-provider.context";
export default function ApiServices() {
  
    return {

        async oauthToken(code, codeVerifier, redirect_uri) {

            return fetch(config.authen.api.host_url + config.authen.api.token, {
                method: 'POST', headers: {
                    'Content-Type': "application/x-www-form-urlencoded",
                }, body: new URLSearchParams({
                    'grant_type': config.authen.grant_type,
                    'code': code,
                    'code_verifier': codeVerifier,
                    'client_id': config.authen.client_id,
                    'redirect_uri': redirect_uri
                })
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async postCustom(objectResult, apiEndpoint) {
            const formData = new FormData();
            for ( var key in objectResult ) {
                formData.append(key, objectResult[key]);
            }
            return axios.post(config.chat_gpt.base_url + apiEndpoint, formData )
                .then(res => res.data)
                .catch(errors => errors);

        },
        async uploadResume(fileUpload, filename) {
            const formData = new FormData();
            formData.append("resume", fileUpload, filename);
            formData.append("type", "interview_ai");
            return axios.post(config.upload_resume.base_url + apiConfig.path.upload_resume, formData, {
                headers: {
                    "x-api-key" : config.upload_resume.x_api_key
                }
            } )
                .then(res => res.data)
                .catch(errors => errors);

        },
        async refreshToken(refresh_token) {

            return fetch(config.authen.api.host_url + config.authen.api.token, {
                method: 'POST', headers: {
                    'Content-Type': "application/x-www-form-urlencoded",
                }, body: new URLSearchParams({
                    'grant_type': 'refresh_token',
                    'refresh_token': refresh_token
                })
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async getInputValue() {
            return fetch(config.api.base_url + config.api.getInput, {
                method: 'GET', headers: {
                    'Authorization': 'Basic ' + window.btoa('mang:bat123'),
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async getJDSuggestion() {
            const configs = { headers: { 'Content-Type': 'application/json', 'x-api-key': config.api.x_api_key } };
            return axios.get(config.chat_gpt.base_url  + apiConfig.path.jd_suggest, configs)
                .then(res => res.data)
                .catch(errors => console.log(errors));

        },
        async postAnswerInterviewQuestion(objectResult) {

            return fetch(config.chat_gpt.base_url  + apiConfig.path.result, {
                method: 'POST', headers: {
                    'content-type': 'application/json'
                }, body: JSON.stringify(objectResult)
            }
            ).then(res => res.json()).catch(err => console.log(err))

        },
        async getSkillSuggest(objectResult) {

            return fetch(config.chat_gpt.base_url + apiConfig.path.skill_suggest, {
                method: 'POST', headers: {
                    'content-type': 'application/json'
                }, body: JSON.stringify(objectResult)
            }
            ).then(res => res.json()).catch(err => console.log(err))

        },
        async getSalaryOffer(objectResult) {

            return fetch(config.chat_gpt.base_url + apiConfig.path.salary_offer, {
                method: 'POST', headers: {
                    'content-type': 'application/json'
                }, body: JSON.stringify(objectResult)
            }
            ).then(res => res.json()).catch(err => console.log(err))

        }

    }
}