const apiConfig = {

    path: {
        question: "/question/jobseeker",
        result: "/result/jobseeker",
        jd_suggest: "/job_suggestion",
        jd_generate: "/job_description/generate",
        jd_analyze: "/job_description/analyze",
        skill_suggest: "/skills/generate",
        salary_offer: "/salary/offer",
        upload_resume: "/resume/upload"
    }
}

export default apiConfig;