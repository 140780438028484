import React from 'react';
import { Button } from 'react-bootstrap';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import MIC_OFF from "../../assets/images/mic-off.png";
import MIC_ON from "../../assets/images/mic-on.png";
import { useContext, useEffect, useState } from "react";
import "./speech.component.scss";
import UtilityService from '../../utility/utilities';
import GAConfig from '../../ga-config';
const SpeechComponent = ({ setText, setInputText, inputText, language }: any) => {

    const [isMicOn, setIsMicOn] = useState(false);
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        finalTranscript
    } = useSpeechRecognition();

    const stopListen = () => {
        setIsMicOn(false);
        SpeechRecognition.stopListening().then((res) => {
            setInputText(inputText + transcript+" " );
            resetTranscript();
        })

    }

    const startListen = () => {
        UtilityService().clickSendEvent(GAConfig.event.click, "Use Mic")
        setIsMicOn(true);
        if (!browserSupportsSpeechRecognition) {
            return <span>Browser doesn't support speech recognition.</span>;
        } else {
            if (language == "ENGLISH") {
                SpeechRecognition.startListening({ language: 'en-US', continuous: true })
            } else {
                SpeechRecognition.startListening({ language: 'th-TH', continuous: true })
            }

        }
    }




    return (
        <div className='speech-component d-flex flex-column'>
            <span className='mx-auto vocal-lang-text'>{
               transcript.length > 0 ?  language == "ENGLISH" ? "Saying: " + transcript : "กำลังพูดว่า: " + transcript : ""}</span>
            {
                listening || isMicOn ?

                    <div className='d-flex'>
                        <div className='circle-1'>

                        </div>
                        <div className='circle-2'>

                        </div>
                        <img className="step-mic-img mic-on-control" onClick={() => stopListen()}
                            src={MIC_ON}
                            onMouseOver={e => (e.currentTarget.src = MIC_OFF)}
                            onMouseOut={e => (e.currentTarget.src = MIC_ON)}
                        ></img>
                    </div> :
                    <img className="step-mic-img" onClick={() => startListen()}
                        src={MIC_OFF}
                        onMouseOver={e => (e.currentTarget.src = MIC_ON)}
                        onMouseOut={e => (e.currentTarget.src = MIC_OFF)}

                    ></img>

            }

            <span className='mx-auto vocal-lang-text'>
                {
                    language == "ENGLISH" ? 
                    "speak 'English' only"
                    : "พูดด้วย 'ภาษาไทย' เท่านั้น"
                }
                
                </span>

        </div>
    );
};
export default SpeechComponent;