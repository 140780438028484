import React, { useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ContextProvider } from './context/context-provider.context';
import LandingComponent from './components/landing/landing.component';
import LoadingComponent from './components/loading/loading.component';
import InputModels from './model/input.model';
import ResultComponent from './components/result/result.component';
import StepComponent from './components/step/step.component';
import { AnswerModel } from './model/answer.model';
import LoadingResultComponent from './components/loading-result/loading-result.component';

import CookieConsent from 'react-cookie-consent';
import { Container } from 'react-bootstrap';
import ExtLoading from './components/ext-loading/ext-loading';
function App() {
  const [inputModel, setInputModel] = useState<InputModels>();
  const [fileUpload, setFileUpload] = useState<File>();
  const [answerModel, setAnswerModel] = useState<AnswerModel>();
  const [utterance, setUtterance] = useState<SpeechSynthesisUtterance>(new SpeechSynthesisUtterance());
  const [synth, setSynth] = useState<SpeechSynthesis>(window.speechSynthesis);
  
  return (

    <div className="App">
      <ContextProvider.Provider
        value={{ inputModel, setInputModel, answerModel, setAnswerModel, utterance, setUtterance, synth, setSynth, fileUpload, setFileUpload }}
      >
        <BrowserRouter>

          <Routes>
            <Route path="/" element={<LandingComponent />} />
            <Route path="/loading" element={<LoadingComponent />} />
            <Route path="/result" element={<ResultComponent />} />
            <Route path="/questions" element={<StepComponent />} />
            <Route path="/loading-result" element={<LoadingResultComponent />} />
            <Route path="/ext-loading" element={<ExtLoading />} />
            <Route path='*' element={<LandingComponent />} />
          </Routes>
        </BrowserRouter>
      </ContextProvider.Provider>
      <CookieConsent
        enableDeclineButton
        declineButtonText="ยอมรับเท่าที่จำเป็น"
        location="bottom"
        buttonText="ยอมรับทั้งหมด"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        declineButtonStyle={{ fontSize: "13px", borderRadius: "12px" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "12px" }}
        expires={150}
      >
        <Container>


          เว็บไซต์ของเราใช้คุกกี้ เพื่อมอบประสบการณ์ที่ดียิ่งขึ้นแก่คุณ และเพื่อเสนอเนื้อหา
          บริการ และโฆษณาที่คุณอาจสนใจ คลิกดูข้อมูลเพิ่มเติมได้ที่  <a href="https://scoutout.co/terms-th/">เงื่อนไขการใช้บริการ</a>
          และ <a href="https://scoutout.net/privacy-th/">นโยบายความเป็นส่วนตัว</a>

        </Container>

      </CookieConsent>
    </div>
  );
}

export default App;
