import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InputOneProfileModel } from "../../model/external/input-oneprofile.model";
import InputModels from "../../model/input.model";
import ApiServices from "../../services/api.service";
import ExternalService from "../../services/external/external.service";
import { AnswerModel } from "../../model/answer.model";
import UtilityService from "../../utility/utilities";
import { ContextProvider } from "../../context/context-provider.context";
import Lottie from "lottie-react";
import "../loading/loading.component.scss";
import OpenDoorAnimation from "../../assets/json/opendoor.json";
import LoadingAnimation from "../../assets/json/loading-questions.json";
import ErrorModal from "../../modal/error.modal";
const ExtLoading = () => {
    const [messageError, setMessageError] = useState("");
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [inputOneProfile, setInputOneProfile] = useState<InputOneProfileModel>();
    const [objectResult, setObjectResult] = useState<InputModels>();
    const { inputModel, answerModel, setAnswerModel }: any = useContext(ContextProvider)
    const [resultModel, setResultModel] = useState<AnswerModel>();
    const [isErrorModal, setErrorModal] = useState(false);
    const [isBackHome, setIsBackHome] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const navigate = useNavigate();
    const { utterance, setUtterance }: any = useContext(ContextProvider)
    const { synth }: any = useContext(ContextProvider)
    // Convert URLSearchParams to an object

    useEffect(() => {
        const queryParams: InputOneProfileModel = {};
        searchParams.forEach((value, key) => {
            if (key == "input") {
                const jsonObj = JSON.parse(value);
                queryParams[key] = jsonObj;
            } else {
                queryParams[key] = value;
            }

        });
        console.log(queryParams);
        setInputOneProfile(queryParams);
    }, [])

    useEffect(() => {

        if (inputOneProfile) {
            if (inputOneProfile.input) {
                getQuestions(inputOneProfile);

            }
        }

    }, [inputOneProfile])

    const getQuestions = (inputOneProfile) => {
        const InputModelMod: InputModels = {
            job_title: inputOneProfile.input.job_title,
            job_experience: inputOneProfile.input.job_experience,
            job_salary: inputOneProfile.input.job_salary,
            resume_key: inputOneProfile.input.resume_key,
            job_description: inputOneProfile.input.job_description,
            question_amount: 5,
            question_language: inputOneProfile.input.question_language
        }
        setObjectResult(InputModelMod);
        if(InputModelMod.question_language == "ENGLISH"){
            synth.getVoices().map((item: SpeechSynthesisVoice, index: number) => {
                if (item.name == "Google US English" ) {
                    utterance.voice = item;
                    utterance.lang = item.lang;
                }
            })
        }else{
            synth.getVoices().map((item: any, index: number) => {
                if (item.lang == "th-TH") {
                    utterance.voice = item;
                    utterance.lang = item.lang;
                }
            })
        }
       
        setUtterance(utterance);
        ExternalService().postQuestion(InputModelMod, inputOneProfile.keyToken).then((resData: any) => {
            const res = resData as AnswerModel;
            if (res && res.questions) {
                let questions: string[] = []
                let answer: string[] = [];

                res.questions.map((item: string, index: number) => {
                    if (item.length > 5) {
                        questions.push(item);
                        answer.push("");
                    }

                })
                const answerMod: AnswerModel = {
                    questions: questions,
                    answers: answer
                }
                UtilityService().clickSendEvent("Load Questions from External" + InputModelMod.job_title, "Loading")
                setAnswerModel(answerMod);
                setResultModel(answerMod);
                if (res.is_resume == false) {
                    setErrorModal(true);
                    setIsBackHome(false);
                    setMessageError("ไฟล์ที่อัปโหลดไม่มีข้อมูลของ Resume. <br>กดปิดเพื่อทดลองสัมภาษณ์ด้วยตำแหน่ง " + InputModelMod.job_title)
                } else {
                    setIsDone(true);
                }

            } else {
                if (resData.message == "Request failed with status code 413") {
                    setErrorModal(true);
                    setIsBackHome(true);
                    setMessageError("ไฟล์ที่อัปโหลดมีขนาดใหญ่เกินไป. (ขนาดต้องไม่เกิน 20 MB.)")
                } else {
                    setErrorModal(true);
                    setIsBackHome(true);
                    setMessageError("เกิดข้อผิดพลาดในระบบ. กรุณาลองใหม่อีกครั้ง")
                }


            }
        }).catch((err: any) => {
            console.log(err);
            setErrorModal(true);
            setIsBackHome(true);
            setMessageError("เกิดข้อผิดพลาดในระบบ. กรุณาลองใหม่อีกครั้ง")
        })
    }


    const onanimationEnd = () => {
        if(objectResult && inputOneProfile){
            objectResult.is_external = true;
            objectResult.access_token = inputOneProfile.keyToken
            navigate('/questions', { state: { results: answerModel, input: objectResult } });
        }
      

    }
    const handleErrorModal = () => {
        if (isErrorModal) {
            setErrorModal(false);
            if (isBackHome) {
                // navigate('/')
            } else {
                if (resultModel) {
                    setIsDone(true);
                }

            }

        } else {
            setErrorModal(true);
        }
    }

    return (
        <>
            <div className="loading-comp">
                <div className="loading-control">
                    {
                        isDone ? <>

                            <div className="loading-img-door-control">
                                <span className="loading-img-door-text">ถึงเวลาสัมภาษณ์แล้ว</span>
                                <Lottie animationData={OpenDoorAnimation} loop={false} onComplete={onanimationEnd}
                                    className="loading-img-door" />
                            </div>
                        </> :

                            <><Lottie animationData={LoadingAnimation} loop={true} className="loading-img" />
                                <span className="loading-text">Loading . . . </span>
                            </>
                    }
                </div>
            </div>
            {
                isErrorModal &&
                <ErrorModal open={isErrorModal} setOpen={handleErrorModal} message={messageError} title={"Warning"}></ErrorModal>
            }

        </>
    )
}

export default ExtLoading;