import { Button, Form, ProgressBar } from "react-bootstrap";
import "./step.component.scss";
import { FormEvent, useContext, useEffect, useState } from "react";
import { ContextProvider } from "../../context/context-provider.context";
import { useLocation, useNavigate } from "react-router-dom";
import { AnswerModel } from "../../model/answer.model";
import StepAnswerModel from "../../model/step-answer.model";
import LoadingResultComponent from "../loading-result/loading-result.component";
import ResultModel from "../../model/result.model";
import ARROW_IMG_LEFT from "../../assets/images/left-arrow-dark.png";
import HeaderComponent from "../header/header.component";
import HR_ASK from "../../assets/images/hr-ask.png";
import CANDIDATE from "../../assets/images/candidate.png";
import SpeechComponent from "../speech/speech.component";
import SPEAKER from "../../assets/images/speaker.png";
import FooterComponent from "../footer/footer.component";
import InputModels from "../../model/input.model";
const StepComponent = () => {
    const [resultSpeech, setResultSpeech] = useState('')
    const [inputText, setInputText] = useState('')
    const navigate = useNavigate();
    const { state } = useLocation();
    const [answerModel, setAnswerModel] = useState<AnswerModel>();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [answerCurrent, setAnswerCurrent] = useState<StepAnswerModel[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isShowResult, setIsShowResult] = useState(false);
    const [textValue, setTextValue] = useState<string>('');
    const { utterance }: any = useContext(ContextProvider);
    const { synth }: any = useContext(ContextProvider);
    const [inputModel, setInputModel] = useState<InputModels>();

    useEffect(() => {
        window.scrollTo(0, 0);
        speak();

    }, [answerCurrent]);

    useEffect(() => {

        if (synth && utterance) {
            speak();
        } else {
            if (answerCurrent && answerCurrent.length > 0 && utterance) {
                setTextValue(answerCurrent[0].questions!)
            }
        }
    }, [synth])

    useEffect(() => {
        if (resultSpeech.length > 0 && resultSpeech != '') {
            setInputText(inputText + resultSpeech);
        }
    }, [resultSpeech])

    const speak = () => {
        if (answerCurrent && answerCurrent.length > 0 && utterance && synth) {

            setTextValue(answerCurrent[currentQuestion - 1].questions!)
            const uttstep = utterance as SpeechSynthesisUtterance;
            uttstep.text = answerCurrent[currentQuestion - 1].questions!;
            uttstep.rate = 1;
            uttstep.pitch = 1;
            uttstep.volume = 2;
            window.speechSynthesis.cancel();
            window.speechSynthesis.speak(uttstep);
        }
    }


    useEffect(() => {

        if (state && state.input) {
            setInputModel(state.input);
        }
        if (state && state.results) {
            setAnswerModel(state.results);

        } else {
            let questions: string[] = []
            let answer: string[] = [];


            const answerMod: AnswerModel = {
                questions: questions,
                answers: answer,
                company: "inputModel.company"
            }
            setAnswerModel(answerMod)

        }

    }, [])

    const onClickNext = () => {
        if (answerCurrent) {
            setCurrentQuestion(currentQuestion + 1)
            setProgress(true);
            const ansCurMod: StepAnswerModel[] = [...answerCurrent];
            ansCurMod[currentQuestion - 1].answers = inputText;
            setAnswerCurrent(ansCurMod)
            setInputText('');
            setResultSpeech('');
            initalValue();
            window.scrollTo(0, 0);
        }
    }

    useEffect(() => {
        if (answerCurrent) {
            initalValue()

        }

    }, [currentQuestion])

    const initalValue = () => {

        if (answerCurrent) {
            if (answerCurrent[currentQuestion - 1] != undefined) {
                setInputText(answerCurrent[currentQuestion - 1].answers + "")
            } else {
                setResultSpeech("");
            }
        }

    }
    const onClickPrev = () => {
        if (answerCurrent) {
            setCurrentQuestion(currentQuestion - 1)
            setProgress(false);
            setInputText(answerCurrent[currentQuestion - 2].answers + "")
        }
    }

    const handleTextArea = (e) => {
        setInputText(e.target.value);

    }



    useEffect(() => {
        if (answerModel && answerModel.questions) {
            const ansCurMod: StepAnswerModel[] = [...answerCurrent];
            answerModel.questions.map((item: string, index: number) => {
                let ansItem: StepAnswerModel = {
                    answers: "",
                    questions: item
                }
                ansCurMod.push(ansItem);
            })

            setAnswerCurrent(ansCurMod)
            setProgress(true)
        }
    }, [answerModel])

    const clickSubmit = () => {

        if (answerCurrent && answerModel) {
            const ansCurMod: StepAnswerModel[] = [...answerCurrent];
            ansCurMod[currentQuestion - 1].answers = inputText;
            let answer: string[] = [];
            ansCurMod.map((item: StepAnswerModel, index: number) => {
                answer.push(item.answers + "");
            })
            const ansMod = answerModel;
            ansMod.answers = answer;
            ansMod.question_language = inputModel?.question_language;
            ansMod.introduce_myself = answerCurrent[0].answers
            setAnswerModel(ansMod);
            setIsSubmitting(true);
        }
    }

    const backtoHome = () => {
        navigate('/')
    }

    const setProgress = (isNext) => {
        if (answerModel && answerModel.questions) {
            if (isNext) {
                const progress = (100 / answerModel.questions.length) * (currentQuestion + 1)
                setCurrentProgress(progress)
                setCurrentQuestion(currentQuestion + 1)
            } else {
                const progress = (100 / answerModel.questions.length) * (currentQuestion - 1)
                setCurrentProgress(progress)
                setCurrentQuestion(currentQuestion - 1)
            }

        }
    }




    return (
        <>
            <HeaderComponent></HeaderComponent>
            <div className="container step-comp">
                <div className="step-control">

                    <div className="step-right-control">
                        {
                            inputModel && inputModel.is_external ? <></> : <div className="breadcrumbs-control" onClick={backtoHome}>
                                <img src={ARROW_IMG_LEFT} className="breadcrumbs-img"></img>
                                <span className="breadcrumbs-text">ย้อนกลับ</span>
                            </div>
                        }

                        <div className="step-questions-number-control">
                            {
                                (answerCurrent && !isSubmitting && !isShowResult) &&
                                <div className="step-progress-bar-control">
                                    <span className="step-progress-text">{currentQuestion}/{answerCurrent.length}</span>
                                    <ProgressBar now={currentProgress} />

                                </div>
                            }

                            {/* <div className="step-terms-control">
                                        <Button className="step-terms-link" onClick={() => clickLink(config.app.terms)}>Terms of Services</Button>
                                        <Button className="step-terms-link" onClick={() => clickLink(config.app.privacy)}>Privacy policy</Button>
                                    </div>
                                    <span className="step-copyright-text">© ScoutOut Limited 2016 - 2023. All Rights Reserved</span> */}
                        </div>


                        {
                            answerCurrent && <>

                                {
                                    (!isSubmitting && !isShowResult) &&
                                    <div className="step-questions-control">


                                        <div className="step-hr-ask-control">

                                            <img src={HR_ASK} className="step-hr-ask-img"></img>
                                            {
                                                (answerCurrent && answerCurrent.length > 0) &&
                                                <div className="step-hr-bubble-control">
                                                    <blockquote>

                                                        <p className="blockquote-text">{answerCurrent[currentQuestion - 1].questions}</p>

                                                        <img src={SPEAKER} className="speak-img" onClick={speak}></img>

                                                    </blockquote>

                                                </div>
                                            }
                                        </div>


                                        <div className="step-questions-textarea-control">

                                            <div className="step-questions-textarea">
                                                <Form.Control as="textarea" rows={5}
                                                    value={inputText}
                                                    onChange={(e) => handleTextArea(e)} ></Form.Control>
                                                <div className="step-questions-button-control">
                                                    {
                                                        currentQuestion != 1 && <Button className="step-questions-back" onClick={onClickPrev}>ย้อนกลับ</Button>
                                                    }
                                                    {
                                                        answerCurrent &&
                                                            (currentQuestion != answerCurrent.length) ?
                                                            <Button className="step-questions-button" onClick={onClickNext}>ถัดไป</Button> :
                                                            <Button className="step-questions-button" onClick={clickSubmit}>ส่งข้อมูล</Button>
                                                    }


                                                </div>
                                            </div>

                                            <img src={CANDIDATE} className="step-questions-candidate-img"></img>


                                            {
                                                inputModel &&
                                                <div className="step-mic-control">

                                                    <SpeechComponent setText={setResultSpeech} setInputText={setInputText} inputText={inputText} language={inputModel.question_language}></SpeechComponent>

                                                </div>
                                            }
                                            <div className="step-footer-control">
                                                <FooterComponent></FooterComponent>
                                            </div>

                                        </div>

                                    </div>
                                }{
                                    isSubmitting && <LoadingResultComponent answers={answerModel} inputModel={inputModel} ></LoadingResultComponent>
                                }
                            </>
                        }

                    </div>

                </div>
            </div >

        </>
    )
}

export default StepComponent;