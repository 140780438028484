


import { Button, Modal } from "react-bootstrap";
import "./error.modal.scss";
import ERROR_FILE_JSON from "../assets/json/error.json";
import Lottie from "lottie-react";


const ErrorModal = ({ open, setOpen, message, title }: any) => {

    const handleClose = () => {
        setOpen(false);
    };




    return (

        <>
            <Modal show={open}  >

                <Modal.Body className="">

                    <div className="error-modal">
                        <div className="error-control">
                            <Lottie animationData={ERROR_FILE_JSON} loop={true} className="error-img" />
                            <span className="error-header">{title}</span>
                            <span className="error-text" dangerouslySetInnerHTML={{__html:message}}></span>
                            <span className="error-close" onClick={handleClose}>ปิด</span>

                        </div>
                    </div>


                </Modal.Body>


            </Modal>
        </>
    )
}

export default ErrorModal;