import "./landing.component.scss";
import IMG_COVER_LANDING from "../../assets/images/img-landing-cover.png";
import { useEffect, useState, useContext } from "react";
import InputModels from "../../model/input.model";
import { Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import ApiServices from "../../services/api.service";
import { Experience, Role } from "../../model/pre-input-suggest.model";
import PreInputSuggestModel from "../../model/pre-input-suggest.model";
import Lottie from "lottie-react";
import config from "../../config";
import LoadingAnimation from "../../assets/json/loading.json";
import JDSuggestionModel from "../../model/jd-suggestion.model";
import { useNavigate } from "react-router-dom";
import { ContextProvider } from "../../context/context-provider.context";
import HeaderComponent from "../header/header.component";
import FooterComponent from "../footer/footer.component";
import { useFilePicker } from 'use-file-picker';
import ErrorModal from "../../modal/error.modal";
import UtilityService from "../../utility/utilities";
import GAConfig from "../../ga-config";
import PopupModal from "../../modal/popup.modal";
const LandingComponent = () => {
    let inputRef: any;
    const navigate = useNavigate();
    const [inputValues, setInputValues] = useState<PreInputSuggestModel>();
    const [experience, setExperience] = useState(0);
    const [language, setLanguage] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [autocompleteList, setAutocompleteList] = useState<string[]>([]);
    const [jobRole, setJobRole] = useState("");
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [currentSalary, setCurrentSalary] = useState(0);
    const [companyName, setCompanyName] = useState("");
    const [isLookingJobs, setLookingJobs] = useState(false);
    const [jdSuggestion, setJdSuggestion] = useState<JDSuggestionModel[]>([]);
    const { setInputModel }: any = useContext(ContextProvider);
    const { utterance, setUtterance }: any = useContext(ContextProvider)
    const { synth }: any = useContext(ContextProvider)
    const [fileUpload, setFileUpload] = useState<any>();
    const [isErrorModal, setErrorModal] = useState(false);
    const [showPopup, setShowPopup] = useState(true);
    const [messageError, setMessageError] = useState("");
    const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
        multiple: false,
        readAs: 'DataURL',
        accept: ['.json', '.pdf', 'image/*'],
        limitFilesConfig: { min: 0, max: 1 },
        maxFileSize: 20,
    });
    const handleErrorModal = () => {
        if (isErrorModal) {
            setErrorModal(false);
            navigate('/')
        } else {
            setErrorModal(true);
        }
    }

    useEffect(() => {
        getInputValueApi();
        getJDSuggestion();
        UtilityService().setPageTitle("/landing");
    }, [])

    useEffect(() => {
        if (errors.length && errors[0].fileSizeToolarge) {
            setErrorModal(true);
            setMessageError("อัปโหลดไฟล์ไม่เกิน 20 MB")
        }
    }, [errors])




    const handleChange = (event: any) => {
        const fileUploaded = event.target.files[0];

        if (fileUploaded) {
            UtilityService().clickSendEvent(GAConfig.event.click, "Choose Resume " + fileUploaded.filename)
            setFileUpload(fileUploaded);
        }

    };

    const getInputValueApi = () => {
        ApiServices().getInputValue().then((res: PreInputSuggestModel) => {
            setInputValues(res);
            let listat: string[] = new Array();
            res.roles.map((item: Role, index: number) => {
                listat.push(item.role.toString());
            })
        })
    }

    const getJDSuggestion = () => {
        ApiServices().getJDSuggestion().then((res: any) => {
            const resData = res as JDSuggestionModel[];
            setJdSuggestion(resData);
            const autoComp: string[] = [];
            resData.map((item: JDSuggestionModel, index: number) => {
                autoComp.push(item.job_title);
            })
            setAutocompleteList(autoComp)
        })
    }

    const handleExpChange = (event: any) => {
        setIsSubmiting(false);
        setExperience(event.target.value)
    }
    const handleLanguageChange = (event: any) => {
        setIsSubmiting(false);
        setLanguage(event.target.value)

        if (event.target.value == "ENGLISH") {
            let isChoose = false;
            synth.getVoices().map((item: SpeechSynthesisVoice, index: number) => {
                if (item.name == "Google US English" && !isChoose) {
                    utterance.voice = item;
                    utterance.lang = item.lang;
                    isChoose = true;
                }
            })
        } else {
            synth.getVoices().map((item: any, index: number) => {
                if (item.lang == "th-TH") {
                    utterance.voice = item;
                    utterance.lang = item.lang;
                }
            })
        }
        setUtterance(utterance);
    }
    const handleJobRoleChange = (event: any) => {
        setIsSubmiting(false);
        if (jdSuggestion) {
            jdSuggestion.map((item: JDSuggestionModel, index: number) => {
                if (item.job_title === event[0]) {
                    setJobRole(item.job_title);
                    setJobDescription(item.job_description)
                }
            })
        }
    }

    useEffect(() => {
        if (filesContent.length > 0) {
            setFileUpload(filesContent[0])
        }
    }, [filesContent])

    const submit = (e) => {
        e.preventDefault();
        setIsSubmiting(true);
        if (jobRole && jobDescription && currentSalary && language && experience && fileUpload) {
            const file = DataURIToBlob(fileUpload["content"]);
            const inputObject: InputModels = {
                job_title: jobRole,
                job_description: jobDescription,
                job_salary: currentSalary,
                question_amount: 5,
                question_language: language,
                job_experience: experience,
                company: companyName,
                resume: file,
                filename: fileUpload.name
            }
            setInputModel(inputObject);
            UtilityService().clickSendEvent("Submit Resume", "Click Submit " + inputObject.job_title)
            navigate("/loading", { state: { input: inputObject } });
        } else {

        }
    }

    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)
        return new Blob([ia], { type: mimeString })
    }


    const handleClosePopup = () => {
        setShowPopup(false);
    }
    const handleSalaryChange = (event: any) => {
        setIsSubmiting(false);
        setCurrentSalary(event.target.value)
    }
    const handleCompanyChange = (event: any) => {
        setIsSubmiting(false);
        setCompanyName(event.target.value)
    }
    const handleLookingJobs = (event: any) => {
        setIsSubmiting(false);
        setLookingJobs(event.target.checked);
    }
    const clickCreateResume = () => {
        UtilityService().clickSendEvent("Create resume builder", "Open Resume Builder");
        window.open(config.app.resume_builder, "_blank");
    }
    return (

        <div className="landing-comp">
            <HeaderComponent></HeaderComponent>
            <div className="container ">
                <div className="landing-cover-section">
                    <div className="landing-grid-control">
                        <div className="landing-grid-left-control order-md-0 order-1">
                            <div className="d-flex w-100">
                                <div className="form-bg-border"></div>

                                <div className="form-border-control d-flex flex-column">

                                    {
                                        autocompleteList ?

                                            <Form className="d-flex flex-column" id="form_submit" >
                                                <h2 className="text-form-header">กรอกข้อมูลในการเตรียมสัมภาษณ์งาน</h2>

                                                <Form.Label htmlFor="position" className="text-title">ตำแหน่งงานที่สมัคร (Job Title)</Form.Label>

                                                <Form.Group>
                                                    <Typeahead
                                                        onChange={handleJobRoleChange}
                                                        id="valid-styles-example"
                                                        inputProps={{
                                                            className: 'input-control',
                                                        }}
                                                        options={autocompleteList}
                                                    />
                                                </Form.Group>
                                                {
                                                    (!jobRole && isSubmiting) && <Form.Text className="text-error" >
                                                        กรุณากรอกตำแหน่งงานที่สมัคร
                                                    </Form.Text>
                                                }

                                                <div className="d-flex flex-column item-control">
                                                    <Form.Label htmlFor="experience" className="text-title">ประสบการณ์ทำงานทั้งหมด (Experiences)</Form.Label>
                                                    <Form.Select className="input-control" onChange={handleExpChange} >
                                                        <option value="">เลือกประสบการณ์</option>
                                                        {
                                                            inputValues &&
                                                            inputValues.experiences.map((item: Experience, index: number) => {
                                                                return (
                                                                    <option value={item.value} key={index}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                    {
                                                        (!experience && isSubmiting) && <Form.Text className="text-error" >
                                                            เลือกประสบการณ์
                                                        </Form.Text>
                                                    }
                                                </div>


                                                <Form.Label htmlFor="position" className="text-title">เงินเดือนปัจจุบัน (Current salary)</Form.Label>
                                                <Form.Control className="input-control"
                                                    type="number"
                                                    onChange={handleSalaryChange}
                                                    id="position"
                                                />
                                                {
                                                    (!currentSalary && isSubmiting) && <Form.Text className="text-error" >
                                                        กรุณากรอกเงินเดือน
                                                    </Form.Text>
                                                }



                                                <div className="d-flex flex-column item-control">
                                                    <Form.Label htmlFor="experience" className="text-title">อัปโหลด Resume ของคุณ (Upload resume)</Form.Label>
                                                    {
                                                        fileUpload ?
                                                            <span className="upload-text" onClick={() => openFileSelector()} >{fileUpload.name}</span> :

                                                            <>
                                                                <Button className={(!fileUpload && isSubmiting) ?
                                                                    "button-upload button-border-red" : "button-upload"
                                                                } onClick={() => openFileSelector()}>อัปโหลดเรซูเม่</Button>

                                                                <span className="upload-text-title">อัปโหลดเรซูเม่ของคุณเป็นไฟล์ .pdf, .docx, .jpeg หรือ .png </span>
                                                                <span className="upload-text-title">ขนาดไม่เกิน 20MB </span>
                                                            </>
                                                    }
                                                    <input
                                                        type="file"
                                                        ref={refParam => inputRef = refParam}
                                                        accept="image/*"
                                                        onChange={handleChange}
                                                        style={{ display: 'none' }} />
                                                    {
                                                        (!fileUpload && isSubmiting) && <Form.Text className="text-error" >
                                                            กรุณาอัปโหลดเรซูเม่
                                                        </Form.Text>
                                                    }

                                                    <h3 className="resume-builder-text" onClick={clickCreateResume}>
                                                        หรือ สร้างเรซูเม่ด้วย <span className="resume-builder-text-special">Resume Builder</span> ฟรี!
                                                    </h3>
                                                </div>
                                                <div className="d-flex flex-column item-control">
                                                    <Form.Label htmlFor="experience" className="text-title">ภาษาที่ต้องการสัมภาษณ์ (Language Interview)</Form.Label>
                                                    <Form.Select className="input-control" onChange={handleLanguageChange} >
                                                        <option value="">เลือกภาษา</option>
                                                        <option value="THAI" >ไทย</option>
                                                        <option value="ENGLISH" >English</option>
                                                    </Form.Select>
                                                    {
                                                        (!language && isSubmiting) && <Form.Text className="text-error" >
                                                            เลือกภาษาที่ต้องการสัมภาษณ์
                                                        </Form.Text>
                                                    }
                                                </div>
                                                <span className="text-consent">
                                                    เมื่อกดปุ่ม 'เริ่มสัมภาษณ์' ถือว่าฉันได้อ่านและยอมรับ <a href={config.app.terms} className="blue">ข้อกําหนด/เงื่อนไขการใช้งาน</a>
                                                    และ <a href={config.app.privacy} className="blue">นโยบายความเป็นส่วนตัว</a>
                                                </span>
                                                <button className="button-submit" type="button" onClick={(e) => submit(e)}>เริ่มสัมภาษณ์</button>
                                            </Form>
                                            :
                                            <div className="d-flex my-auto">
                                                <div className="border-loading-control ">
                                                    <Lottie animationData={LoadingAnimation} loop={true} className="loading-json mx-auto my-auto" />
                                                    <span className="loading-text">Loading . . . </span>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="landing-grid-right-control order-md-1 order-0">
                            <div className="text-control">
                                <img src={IMG_COVER_LANDING} className="img-headline"></img>
                                <h1 className="text-headline">สัมภาษณ์งานด้วยระบบ AI</h1>
                                <span className="text-subheadline">ประหยัดเวลา แม่นยำ และเป็นมนุษย์อย่างเท่าเทียม!</span>
                                <span className="text-descheadling">ระบบสัมภาษณ์งานออนไลน์ที่ใช้เทคโนโลยี AI เพื่อเพิ่มประสิทธิภาพและประหยัดเวลาในกระบวนการสรรหาบุคคลากร</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {
                isErrorModal &&
                <ErrorModal open={isErrorModal} setOpen={handleErrorModal} message={messageError} title={"Warning"}></ErrorModal>
            }
            <FooterComponent></FooterComponent>

{
    showPopup &&  <PopupModal showMod={showPopup} handleClose={handleClosePopup} />
}
           
        </div>
    )
}

export default LandingComponent;