import axios from "axios";
import { fileURLToPath } from "url";
import { useContext } from "react";
import config from "../../config";
import tokenService from "../sso/token.service";
export default function ExternalService() {
  
    return {

        async postQuestion(objectResult, accessToken) {
           
            const url = config.api.base_url_lambda + "/question";
            console.log(url);
            return axios.post(url, objectResult,{
                headers: {
                    "Content-Type": 'application/json',
                    'scoutout-authorization': accessToken,
                }
            } )
                .then(res => res.data)
                .catch(errors => Promise.reject(errors));

        },
        async postResult(objectResult, accessToken) {
        
            const url = config.api.base_url_lambda + "/result";
            console.log(url);
            return axios.post(url, objectResult,{
                headers: {
                    "Content-Type": 'application/json',
                    'scoutout-authorization': accessToken,
                }
            } )
                .then(res => res.data)
                .catch(errors => Promise.reject(errors));

        },
    }
}