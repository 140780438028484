import "./footer.component.scss";

const FooterComponent = () => {


    return (
        <>
            <div className="footer-comp">
                <div className="footer-control">
                    <div className="footer-link-control">
                        <a className="footer-link">Terms of Services</a>
                        <a className="footer-link">Privacy policy</a>
                    </div>
                    <span className="footer-copy-right">© ScoutOut Limited 2016 - 2023. All Rights Reserved</span>
                </div>

            </div>
        </>
    )
}

export default FooterComponent;